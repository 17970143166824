import React, { useState, useRef, useCallback } from 'react';
import { SearchState, IntegratedFiltering } from '@devexpress/dx-react-grid';
import { Grid, Table, TableHeaderRow, Toolbar, TableColumnResizing, SearchPanel, VirtualTable, ExportPanel } from '@devexpress/dx-react-grid-bootstrap4';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import '@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css';
import { navigate } from 'gatsby-link';
import clienteAxios from '../../../../config/axios';
import { useSelector } from 'react-redux';
import saveAs from 'file-saver';

const getRowId = row => row.id;
const Expiration = () => {

    const exporterRef = useRef(null);

    const startExport = useCallback((options) => {
        exporterRef.current.exportGrid(options);
    }, [exporterRef]);

    const token = useSelector(state => state.auth.token)
    const [expirationsData, setExpirationsData] = useState([])

    React.useEffect(() => {
        const config = {
            headers: { 'Authorization': "Bearer " + token }
        };
        clienteAxios.get(`/api/payment/vencimientos`, config)
            .then((res) => {
                // console.log(res.data)
                let expirations = []
                for (let i = 0; i < res.data.length; i++) {
                    let expiration = res.data[i]
                    expirations.push({
                        cliente: expiration.space.client.name + ' ' + expiration.space.client.lastname,
                        lote: expiration.space.lote,
                        mensura: expiration.space.mensura,
                        vence: expiration.expiration ? expiration.expiration.split("T")[0].split("-")[2] + "-" + expiration.expiration.split("T")[0].split("-")[1] + "-" + expiration.expiration.split("T")[0].split("-")[0] : "",
                        cuota: expiration.price,
                        // saldo: "",
                        estado: expiration.paymentStatus,
                        id: expiration._id,
                        idToDetails: expiration.space._id,
                    })
                }
                setExpirationsData(expirations)
            })
            .catch(error => {
                // console.log(error)
            })
    }, [])

    const [columns] = React.useState([
        { name: 'cliente', title: 'Cliente' },
        { name: 'lote', title: 'Lote' },
        { name: 'mensura', title: 'Mensura' },
        { name: 'vence', title: 'Vence' },
        { name: 'cuota', title: 'Cuota' },
        // { name: 'saldo', title: 'Saldo deudor' },
        { name: 'estado', title: 'Estado' },
    ]);

    const [tableColumnExtensions] = useState([
        { columnName: 'cliente', width: 250 },
        { columnName: 'lote', width: 250 },
        { columnName: 'mensura', width: 250 },
        { columnName: 'vence', width: 250 },
        { columnName: 'cuota', width: 250 },
        // { columnName: 'saldo', width: 250 },
        { columnName: 'estado', width: 250 },
    ]);


    const HighlightedCell = ({ value, style, ...restProps }) => (
        <Table.Cell {...restProps}>
            <span style={{ color: value === "Vencido" ? '#DD3434' : "#039487" }}>
                {value}
            </span>
        </Table.Cell>
    );

    const Cell = (props) => {
        const { column } = props;
        if (column.name === 'estado') {
            return <HighlightedCell {...props} />;
        }
        return <Table.Cell {...props} />;
    };


    const TableRow = ({ row, ...restProps }) => (
        <Table.Row
            {...restProps}
            onClick={() => navigate(`/dashboard/clientes/detalles/?id=${row.idToDetails}`)}
            style={{
                cursor: 'pointer',
            }}
        />
    );


    const onSave = (workbook) => {
        workbook.xlsx.writeBuffer().then((buffer) => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'DataGrid.xlsx');
        });
    };


    return (
        <div className="card">
            <Grid rows={expirationsData} columns={columns} getRowId={getRowId} >
                <SearchState />
                <IntegratedFiltering />
                <Table />
                <VirtualTable height={670} cellComponent={Cell} rowComponent={TableRow} />
                <TableColumnResizing defaultColumnWidths={tableColumnExtensions} />
                <TableHeaderRow />
                <Toolbar />
                <ExportPanel startExport={startExport} />
                <SearchPanel />
            </Grid>
            <GridExporter
                ref={exporterRef}
                rows={expirationsData}
                columns={columns}
                onSave={onSave}
            />
        </div>
    );
}

export default Expiration;